





import Vue from "vue";

export default class TaxonomyUploadPage extends Vue{
}
